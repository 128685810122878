



















import Vue, { PropType } from "vue";

export interface SelectOptions {
  id: number | string;
  name: string;
}

export default Vue.extend({
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
        type: String,
    },
    options: {
      type: Array as PropType<Array<SelectOptions>>,
    },
    value: {
      type: String,
    },
  },
  methods: {
    selected(e: Event) {
      const target = <HTMLSelectElement>e.target;
      if (target.value) {
        this.$emit("change", target.value);
      }
    },
  },
});
